import { Countdown } from "@ft9/countdown";

const countdown = new Countdown();

const cinemas = document.getElementsByClassName("ft9-benefit-paper-card__cinema");

for (const cinema of cinemas) {
	const items = cinema.getElementsByClassName("benefit-js-countdown") as HTMLCollectionOf<HTMLElement>;
	countdown.cinemaCountdownInit(items);
}
